import axios from 'axios';
import request from '@/plugins/request';
import store from '@/store';

const { CancelToken } = axios;

export function getScrolls({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/store-scroll-infos',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      store.commit('setScrolls', { scrolls: response.data.list });
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setScroll({ store_id, content, showError = true }) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store-scroll-infos/update',
    data: {
      store_id,
      content,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getScrolls();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function removeScrolls({ store_ids, showError = true }) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store-scroll-infos/delete',
    data: {
      store_ids,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getScrolls();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}
