import axios from 'axios';
import { camelCase } from 'change-case';
import request from '@/plugins/request';

const { CancelToken } = axios;

export function getNotice({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/notable/get',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data = {
        ...response.data,
        notice: response.data.content,
      };
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setNotice({ notice, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/notable/set',
    data: { content: notice },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getDiscount({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/discount/get',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function setDiscount({ discount, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/discount/set',
    data: {
      discount,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getConfigs({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/configurations',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data.configurations = Object.fromEntries(
        Object.entries(response.data.configurations).map((item) => [camelCase(item[0]), item[1]]),
      );
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setConfigs({
  enableWalkingDistance, // '0'
  enableOrderMpInfo, // '1'
  enableOrderWxappInfo, // '1'
  enableOrderAliappInfo, // '1'
  enableStoreRotation, // '0'
  enableOrderAddition, // '0'
  timeOfOrderAddition, // '3600'
  enableAutoUpdateStoreStatus, // '0'
  enableStoreDistanceTemplate, // '0'
  enableStoreDishesQuantity, // '0'
  enableGroupBuy, // '0'
  enableStoreServiceTemplate, // '0'
  orderDiscount, // '100'
  orderMinAmount, // '2000'
  autoMatchDistanceToAddress, // '200'
  enableGeoLocation, // '1'
  extraMeatPrice, // '300'
  showError = true,
} = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/configuration',
    data: {
      EnableWalkingDistance: enableWalkingDistance,
      EnableOrderMpInfo: enableOrderMpInfo,
      EnableOrderWxappInfo: enableOrderWxappInfo,
      EnableOrderAliappInfo: enableOrderAliappInfo,
      EnableStoreRotation: enableStoreRotation,
      EnableOrderAddition: enableOrderAddition,
      TimeOfOrderAddition: timeOfOrderAddition,
      EnableAutoUpdateStoreStatus: enableAutoUpdateStoreStatus,
      EnableStoreDistanceTemplate: enableStoreDistanceTemplate,
      EnableStoreDishesQuantity: enableStoreDishesQuantity,
      EnableGroupBuy: enableGroupBuy,
      EnableStoreServiceTemplate: enableStoreServiceTemplate,
      OrderDiscount: orderDiscount,
      OrderMinAmount: orderMinAmount,
      AutoMatchDistanceToAddress: autoMatchDistanceToAddress,
      EnableGeoLocation: enableGeoLocation,
      ExtraMeatPrice: extraMeatPrice,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function sync({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/data-synchronize',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function inspect({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/dish-library/check',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}
