import axios from 'axios';
import request from '@/plugins/request';
import store from '@/store';

const { CancelToken } = axios;

export function fetchPolygons({ store_id, status, type, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/store-polygons',
    params: {
      store_id,
      status,
      type,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      store.commit('setPolygons', { polygons: response.data.list });
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function updatePolygons({ list, showError = true }) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-polygons/batch-create`,
    data: {
      list,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      fetchPolygons();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function removePolygons({ ids, showError = true }) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-polygons/delete`,
    data: {
      ids,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      fetchPolygons();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}
